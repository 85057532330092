:root {
    --bg-head: #f0fffe;
    --bg-greenblue: #2dbcb6;
    --bg-light-green: #b5e7e6;
    --bg-light-greenblue: #ddf3f2;
    --bg-dark-blue: #003873;
    --color-blue: #4377a2;
    --color-blue-light: #00AAA9;
  }
  .main-login{
    background: var(--bg-head);
    min-height: 100dvh;
    display: grid;
    place-items: center;
  }
.login-container {
    background-color: var(--bg-head);
    border: 1px solid var(--color-blue-light);
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    min-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-title {
    color: var(--bg-dark-blue);
    text-align: center;
    margin-bottom: 20px;
}

.login-form .form-group {
    margin-bottom: 15px;
}

.login-form label {
    display: block;
    margin-bottom: 5px;
    color: var(--color-blue);
}

.login-form .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--color-blue-light);
    border-radius: 4px;
}

.error-text {
    color: red;
    font-size: 0.875em;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: var(--bg-greenblue);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.login-button:hover {
    background-color: var(--color-blue-light);
}

@media only screen and (max-width:500px) {
    .login-container {
        min-width: auto;
        width: 90%;
        
    }
}


.password-group {
    position: relative;
  }
  
  .password-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .password-wrapper .password-toggle-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: var(--color-blue);
  }
  
  .password-toggle-icon:hover {
    color: var(--color-blue-light);
  }
  